import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Iframely,
  Seo,
  FeatureImage,
} from "../components"
import {
  FixedPageH1,
  FixedPageH2,
  FixedPageH3,
  ProfileWrapper,
} from "../elements"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const profilePage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="会社概要"
        pagedesc="株式会社マルスエ仏壇のプロフィール"
        pagepath={location.pathname}
        pageimg={data.shop.childImageSharp.original.src}
        pageimgw={data.shop.childImageSharp.original.width}
        pageimgh={data.shop.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.shop.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <ProfileWrapper>
            <Content>
              <FixedPageH1><h1>マルスエ佛壇ってどんなお店？</h1></FixedPageH1>
              <FixedPageH2>ご挨拶</FixedPageH2>

              <div className="question">
                <Img
                  fluid={data.sandaime.childImageSharp.fluid}
                  style={{ height: "auto!important" }}
                />
                <div className="question-text">
                  <p>
                    <strong>代表あいさつ</strong>
                  </p>

                  <p>
                    座右の銘：「人を驚かせる技術」
                    <br />
                    趣味：鞘塗り
                    <br />
                    はじめまして、三代目代表の伊藤俊克です。マルスエ佛壇の跡を継ぎ、20年代表をさせて頂いております。まもなく創立100年を迎えるマルスエ佛壇ですが、初代から続く漆塗り職人として、お仏壇に限らず、様々な漆塗りに携わり、クオリティを追求してきました。また、小売業に進出し、地域の仏壇店としてお客さまにご愛顧いただき、様々な気付きを得た20年でもありました。これからも、お客さまに「納得」と「驚き」を、代表として、職人として、次なる四代目と共に届けていきたいと思っております。
                  </p>
                </div>
              </div>
              <div className="question2">
                <Img
                  fluid={data.kanrisha.childImageSharp.fluid}
                  style={{ height: "auto!important" }}
                />
                <div className="question-text">
                  <p></p> 
                  <p>
                    <strong>サイト管理人挨拶</strong>
                    <br />
                    座右の銘：「三方おトク」
                    <br />
                    趣味：キャンプ
                    <br />
                    マルスエサイトを閲覧いただき、ありがとうございます。
                    <br />
                    サイト管理人兼ライター兼現代表の息子、伊藤大輔です。
                    <br />
                    インターネットが普及して、情報が身近になり便利な時代になりました。
                    <br />
                    ですが、情報の正確性を判断するのが難しい時代でもあります。
                    <br />
                    僕自身、漆塗り職人として、13年間技術を磨いてきましたが、その過程で、「ちょっと違う…」という情報がインターネットに溢れていることに、疑問を感じた13年間でもありました。
                    <br />
                    マルスエサイトでは、職人しか書けない記事を、漆塗りの伝統工芸士でもある僕が、執筆していきます。正しい情報で「買い手がおトク」「売り手がおトク」「世間もおトク」になって欲しい想いでコンテンツを制作しています。
                  </p>
                </div>
              </div>
            </Content>
            <Content>
              <FixedPageH2>マルスエ佛壇の経営理念</FixedPageH2>
              <FixedPageH3>「想い」を塗り重ねる</FixedPageH3>
              <p>
                人々の「こだわり、想い」を尊重し、私たち作り手の「こだわり、想い」を掛け合わせてカタチにし、漆塗りのように丁寧に塗り重ねて製品にする。社会の「想い」を実現する職人であり続ける。
              </p>
              {/* <FixedPageH3>お客さまのお悩みを解決する力</FixedPageH3>
              <p>
                マルスエ佛壇はさまざまな技術を保有しています。その礎は、創業以来、お客さまの悩みに傾聴し、時代の流れで変化するお悩みに合わせて、今日まで磨かれてきました。「こんなお仏壇が欲しい」「こんな漆塗りをしてほしい」というお悩みを解決することで、みなさんに満足して頂いております。
              </p>
              <FixedPageH3>製造直販</FixedPageH3>
              <p>
                「良いものを安く買う方法」が可能であることをご存知でしょうか。マルスエ佛壇は「職人のチーム」です。なので、「外注」による中間マージンを減らすことを目標に、製作することが出来ます。例えば、お仏壇を製作する場合、「漆塗り」「呂色」「箔置き」「組み立て」の４つの工程を自社製造することが、「いいものを・欲しい時に・ご希望の予算で作ること」につながります。
              </p>
              <FixedPageH3>歴史が証明する信頼</FixedPageH3>
              <p>
                マルスエ佛壇は創業大正１３年より伝統工芸を磨いてきました。今これを執筆している私で四代目の塗師になります。たとえば、うるしの魅力は？と聞かれたらわたしたちは迷わずお答えします「うるしの良さは、千年以上使われてきたという歴史が証明しています」
              </p> */}
              {/* <FixedPageH3>製造卸業</FixedPageH3>
              <p>
                小売業に比べ、製造卸業はスピードとコストへの意識がシビアです。マルスエ佛壇は、製造卸業にて培われたノウハウで、ほしい時に・ご希望の予算で、お客さまのニーズに応えることが得意です。
              </p> */}
            </Content>
            <Content>
              <FixedPageH2>会社概要</FixedPageH2>
              <table border="1">
                <tbody>
                  <tr>
                    <td width="25%" align="center">
                      商号
                    </td>
                    <td align="center">株式会社マルスエ佛壇</td>
                  </tr>
                  <tr>
                    <td align="center">設立</td>
                    <td align="center">１９２５年（大正１４年）</td>
                  </tr>
                  <tr>
                    <td align="center">資本金</td>
                    <td align="center">１，０００万円</td>
                  </tr>
                  <tr>
                    <td align="center">代表</td>
                    <td align="center">伊藤俊克（三代目）</td>
                  </tr>
                  <tr>
                    <td align="center">業種</td>
                    <td align="center">漆塗り職人</td>
                  </tr>
                  <tr>
                    <td align="center">業務内容</td>
                    <td align="center">
                      仏壇仏具製造販売
                      <br />
                      山車・御神輿・社寺建築・文化財の塗装修復
                    </td>
                  </tr>
                  <tr>
                    <td align="center">加盟団体</td>
                    <td align="center">名古屋仏壇商工協同組合</td>
                  </tr>
                  <tr>
                    <td align="center">所在地</td>
                    <td>
                      【マルスエ佛壇 弥富本店】
                      <br />
                      〒４９８−００２７
                      <br />
                      ☎（０５６７）６７−０７０７
                      <br />
                      愛知県弥富市鯏浦町南前新田１８４番地３
                      <br />
                      営業時間：9:00〜19:00（木曜定休）
                      <br />
                      <br />
                      近鉄弥富駅より徒歩５分。（弥富駅からの送迎はご連絡ください）
                      <br />
                      東名阪自動車道弥富ICより車で５分。
                      <br />
                      ①インターを降りて国道155号線を弥富方面へ左折
                      <br />
                      ②国道１号線の交差点を右折
                      <br />
                      ③４個目の信号を弥富市役所方面へ左折後スグ
                      <br />
                      <br />
                      <br />
                      【マルスエ漆工場】
                      <br />
                      〒４９８−０８０７
                      <br />
                      ☎（０５６７）６８−８９６３
                      <br />
                      三重県桑名郡木曽岬町大字西対海地２６７−２
                      <br />
                      営業時間：9:00〜19:00（日曜定休）
                      <br />
                      <br />
                      近鉄弥富駅より車で１０分。（弥富駅からの送迎はご連絡ください）
                      <br />
                      東名阪自動車道弥富ICより車で１５分。
                      <br />
                      伊勢湾岸自動車道湾岸弥富ICより車で１０分
                    </td>
                  </tr>
                </tbody>
              </table>
            </Content>
            <Content>
              <FixedPageH2>沿革</FixedPageH2>
              <table className="history">
                <tbody>
                  <tr>
                    <td width="25%">1925年</td>
                    <td>
                      初代代表・伊藤末本が名古屋市中区にて仏壇の漆塗り職人として、開業（現・マルスエ佛壇）
                    </td>
                  </tr>
                  <tr>
                    <td>1946年</td>
                    <td>戦後、工房兼本店を名古屋市熱田区へ移転</td>
                  </tr>
                  <tr>
                    <td>1960年</td>
                    <td>製造卸・小売店舗として弥富市へ出店(後の弥富本店)</td>
                  </tr>
                  <tr>
                    <td>1979年</td>
                    <td>豊川工場を設置</td>
                  </tr>
                  <tr>
                    <td>1983年</td>
                    <td>二代目・伊藤静馬が代表就任</td>
                  </tr>
                  <tr>
                    <td>1985年</td>
                    <td>株式会社マルスエ佛壇（現商号）に社名変更</td>
                  </tr>
                  <tr>
                    <td>同年</td>
                    <td>木曽岬工場を設置</td>
                  </tr>
                  <tr>
                    <td>1995年</td>
                    <td>道路拡張の為、弥富本店移転（現・所在地）</td>
                  </tr>
                  <tr>
                    <td>1998年</td>
                    <td>道路拡張の為、木曽岬工場移転（現・所在地）</td>
                  </tr>
                  <tr>
                    <td>同年</td>
                    <td>三代目・伊藤俊克が代表就任（現・代表）</td>
                  </tr>
                </tbody>
              </table>
            </Content>
            <Content>
              <FixedPageH2>実績</FixedPageH2>
              <ul>
                <li>各宗派仏壇製作多数</li>
                <li>各宗派仏壇修復多数</li>
                <li>各宗派寺院修復多数</li>
                <li>岐阜県大垣市 本町 相生軕(山車)漆塗り(市指定無形文化財) </li>
                <li>名古屋市 若宮八幡宮 福禄寿車 塗り直し(市指定文化財)</li>
                <li>名古屋市 名古屋城本丸御殿復元工事</li>
                <li>東京都青梅市 武蔵御嶽神社修復工事(市指定史跡)</li>
                <li>
                  愛知県豊川市 牛久保八幡社山車二輌 塗り直し(市指定無形文化財)
                </li>
                <li>
                  三重県津市 浄土真宗高田派本山専修寺 茶所
                  仏具、及び内陣修復(国指定重要文化財)
                </li>
                <li>静岡市 神部神社浅間神社 楼門修復工事(国指定重要文化財)</li>
                <li>
                  三重県桑名市 職人町 破魔（車輪）漆塗り(ユネスコ無形文化遺産)
                </li>
                <li>
                  愛知県弥富市中六町 祭車 漆塗り変え（摺漆）
                </li>
              </ul>
            </Content>
            <Content>
              <FixedPageH2>Maps</FixedPageH2>
              <div className="iframely-embed">
                <div
                  className="iframely-responsive"
                  style={{ height: 140, paddingBottom: 0 }}
                >
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                    data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                    aria-label="GoogleMaps"
                  >
                    {null}
                  </a>
                </div>
              </div>
            </Content>
          </ProfileWrapper>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    shop: file(relativePath: { eq: "shop.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    shop: file(relativePath: { eq: "shop.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sandaime: file(relativePath: { eq: "sandaime.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kanrisha: file(relativePath: { eq: "kanrisha.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default profilePage
